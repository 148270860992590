<template>
  <div class="container">
    <base-overlay :show="loader">
      <base-sub-card
        v-if="Object.keys(controlPanelProps).length"
        showControls
        @dropdownTypeSelected="
          downLoadTypeMap($event, {
            indicator: controlPanelProps.indicator.short_name,
            datasource: controlPanelProps.datasource.datasource,
            year: controlPanelProps.year,
          })
        "
      >
        <template #title>
          <p class="work-sans mb-0 line-height">
            Distribution of
            <span class="font-weight-bold"> {{ controlPanelProps.indicator.full_name }} </span
            >Across the <span class="font-weight-bold"> zones in the Country.</span> Source:
            <span class="font-weight-bold">
              {{ controlPanelProps.datasource.datasource }}
              {{ controlPanelProps.year }}</span
            >
          </p>
        </template>
        <div id="zonalMapComponent" class="col-12 position-relative">
          <button title="Back to National" @click="returnToNational" v-if="level === 4" class="bg-primary font-weight-bold">
            <b-icon icon="chevron-left" />
            &nbsp;Back to National
          </button>
          <button title="Back to Zonal " @click="returnToZonal" v-if="level === 3" class="bg-primary font-weight-bold">
            <b-icon icon="chevron-left" />
            &nbsp;Back to Zonal
          </button>
          <BaseMap
            ref="BaseMap"
            :title="title"
            :mapObject="chart"
            :level="level"
            :lgaState="stateName"
          />

          <NoAvailableData
            v-if="showNoAvailableData"
            class="position-absolute"
            style="top: 9%; width: 50%; left: 25%"
          />
        </div>
      </base-sub-card>
    </base-overlay>
  </div>
</template>

<script>
import BaseMap from '@/components/maps/BaseMap.vue';
import { eventBus } from '@/main';
import chartDownload from '../../../mixins/chart_download';
import NoAvailableData from '../../NoData2.vue';
import { sortHighChartDataFormat } from '../../../mixins/util';

export default {
  mixins: [chartDownload],
  props: {
    controlPanelProps: {
      type: Object,
    },
    colors: {
      type: [Object, Array],
      required: true,
    },
  },
  components: {
    BaseMap,
    NoAvailableData,
  },
  data() {
    return {
      title: '',
      chart: {},
      loader: false,
      level: 1,
      zone: 2,
      stateName: 'Nigeria',
      showNoAvailableData: false,
    };
  },
  methods: {
    returnToNational() {
      const selectedPlace = this.dlGetLocation({ level: 1 });
      if (selectedPlace.length !== 0) {
        eventBus.$emit('handleClick', selectedPlace[0]);
      }
    },
    returnToZonal() {
      const selectedPlace = this.dlGetLocation({ level: 2 });
      // check if the selectedPlace is an array, if it is filter it by this.zone and then emit the first item
      if (Array.isArray(selectedPlace)) {
        const selectedPlace2 = selectedPlace.filter((item) => item.id === this.zone);
        if (selectedPlace2.length !== 0) {
          eventBus.$emit('handleClick', selectedPlace2[0]);
        }
      }
    },
  },

  watch: {
    controlPanelProps: {
      async handler(val) {
        this.loader = true;
        const data = await this.dlQuery({
          indicator: val.indicator.id,
          datasource: val.datasource.id,
          period: val.year,
        });
        const stateObject = this.dlGetLocation(val.location.id);

        // PLOT 1ST MAP AS ZOANL
        if (stateObject.level === 1) {
          const groupP = data.filter((item) => this.dlGetLocation(item.location).parent === 1);
          if (groupP.length === 0) {
            this.showNoAvailableData = true;
            this.chart = {
              series: [],
            };
            this.loader = false;
            return;
          }

          this.showNoAvailableData = false;
          const zData = groupP.map((item) => ({
            color: this.colors.find((item2) => item2.id === item.location).color,
            name: this.dlGetLocation(item.location).name,
            data: [[this.dlGetLocation(item.location).name, parseFloat(item.value)]],
          }));

          this.chart = {
            series: zData,
          };
          this.title = `Distribution of ${val.indicator.full_name} Across the Zones in the Country`;
          this.level = 2;
          // this.stateName = 'Nigeria';
        }
        // PLOT 2ND MAP AS STATE
        if (stateObject.level === 2) {
          this.zone = stateObject.id;
          const filteredStateDataForZone = data.filter(
            (item) => this.dlGetLocation(item.location).parent === stateObject.id,
          );
          if (filteredStateDataForZone.length === 0) {
            this.showNoAvailableData = true;
          } else {
            this.showNoAvailableData = false;
          }

          const sData = filteredStateDataForZone.map((item) => ({
            color: this.colors.find((item2) => item2.id === stateObject.id).color,
            name: this.dlGetLocation(item.location).name,
            data: [[this.dlGetLocation(item.location).name, parseFloat(item.value)]],
          }));

          const stateData = data.find((item) => item.location === val.location.id);

          sData.unshift({
            name: stateObject.name,
            y: parseFloat(stateData?.value),
            color: this.colors.find((item) => item.id === stateObject.id).color,
          });
          this.stateName = stateObject?.name;
          this.level = 4; // for tracking purposes
          this.chart = {
            series: sData,
          };
        }
        // PLOT 3RD MAP AS LGA
        if (stateObject.level === 3) {
          const filteredLGADataForState = data.filter(
            (item) => this.dlGetLocation(item.location).parent === stateObject.id,
          );
          console.log('filteredLGADataForState', filteredLGADataForState);
          if (filteredLGADataForState.length === 0) {
            this.showNoAvailableData = true;
          } else {
            this.showNoAvailableData = false;
          }
          const formatToHighChart = (dataValues) => dataValues.map((item) => [
            this.dlGetLocation(item.location).name,
            parseFloat(item.value),
          ]);
          const chartSeries = [];
          const formattedData = formatToHighChart(filteredLGADataForState);
          const sortedData = formattedData.sort(sortHighChartDataFormat);
          console.log('sorted', sortedData);
          // remove LGAs sting from LGA name cause mapdata does not support the format
          sortedData.forEach((item) => {
            const newItem = item;
            newItem[0] = newItem[0].split('LGA')[0].trim();
            return newItem;
          });

          const stateData = data.find((item) => item.location === val.location.id);

          sortedData.unshift({
            name: stateObject.name,
            y: parseFloat(stateData?.value),
            color: this.colors[0].color,
          });
          chartSeries.push({
            color: this.colors.find((item) => item.id === stateObject.parent).color,
            name: stateObject.name,
            data: sortedData,
          });
          this.stateName = stateObject.name; // Please always change the state name before
          // changing the level else you would get an error
          this.level = 3;
          this.chart = {
            series: chartSeries,
          };
        }
        // if (stateObject.level === 2) {
        //   const formatToHighChart = (dataValues) => dataValues.map((item) => [
        //     this.dlGetLocation(item.location).name,
        //     parseFloat(item.value),
        //   ]);

        //   const chartSeries = [];

        //   for (let index = 0; index < this.colors.length; index += 1) {
        //     const group = data.filter(
        //       (item) => this.dlGetLocation(item.location).parent === this.colors[index].id,
        //     );

        //     // if(group.length > 0 && group.length < 8){
        //     //   // i am assuming that the zonal level data is all that exist so switch zonal level
        //     //     this.level = 2;
        //     // }else{
        //     //   this.level = 3;
        //     // }
        //     const { color } = this.colors.find((item) => item.id === this.colors[index].id);
        //     const formattedData = formatToHighChart(group);
        //     const sortedData = formattedData.sort(sortHighChartDataFormat);
        //     const series = this.dlGetLocation(this.colors[index].id);

        //     chartSeries.push({
        //       color,
        //       name: series.name,
        //       data: sortedData,
        //     });
        //     /**
        //      * Function no fully functional
        //      * ! Need to fix the issue
        //      */
        //     for (let i = 0; i < chartSeries.length; i += 1) {
        //       if (chartSeries[i].data.length === 0) {
        //         this.showNoAvailableData = true;
        //       } else {
        //         this.showNoAvailableData = false;
        //       }
        //     }
        //   }
        //   this.stateName = stateObject.name; // Please always change the state name before
        //   // changing the level else you would get an error
        //   this.level = 2;
        //   this.chart = {
        //     series: chartSeries,
        //   };
        // }

        this.loader = false;
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.title = ` Distribution of ${this.controlPanelProps.indicator.full_name} Across the zones in the Country. Source: ${this.controlPanelProps.datasource.datasource} ${this.controlPanelProps.year}`;
  },
};
</script>
